<template>
	<div
		ref="series_list_item_view"
		class=""
		:class="!is_home || (is_home && view_type != 'all') ? 'prl-20' : ''"
	>
		<template
			v-if="items_series.length > 0"
		>
		<ul
			v-if="!is_home || (is_home && view_type != 'all')"
			class="flex-row flex-wrap justify-space-between gap-10 my-shorts-list"
		>
			<li
				v-for="(series, s_index) in list_series"
				:key="'series_' + s_index"
				class="my-shorts-list shorts-list radius-10 overflow-hidden mb-15"
				@click="toEpisode(series)"
			>
				<div class="overflow-hidden bg-blue02 justify-center items-center">
					<img
						:src="series.cover_img"
						@error="$bus.$emit('onErrorTv', $event)"
						class="object-cover"
					/>
				</div>
				<div
					class="mt-10 color-white size-px-13 font-weight-500"
				>
					{{ series.title}}
				</div>
			</li>
		</ul>
		<ul
			v-if="is_home && view_type == 'all'"
			class="overflow-x-auto white-space-nowrap pr-20"
		>
			<li
				v-for="(series, s_index) in list_series"
				:key="'series_' + s_index"
				class="color-white  mb-10 ml-20 inline-block"
				style="height: 230px; width: calc(100% - 40px)"
				@click="toEpisode(series)"
			>
				<div class="flex-column">
					<div
						class="full-height  radius-20 bg-blue02 flex-column justify-center items-center bg-tb-box"
						style="height: 180px; "
					>
						<img
							v-if="!series.not_view"
							:src="series.cover_img"
							@error="series.not_view = true"
							class="object-cover"
						/>
					</div>
					<div
						class="mt-10 color-white size-px-16 font-weight-500 "
						style="height: 48px"
					>
						{{ series.title}}
					</div>
				</div>
			</li>
		</ul>
		</template>
		<Empty
			v-else
			class="color-white"
			:class="view_type == 'series' ? 'mt-150' : 'mt-50 mb-50'"

			text="등록된 시리즈가 없습니다"
		></Empty>

		<ScrollPagination
			v-if="view_type == 'series' && $refs.series_list_item_view"
			:body="$refs.series_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getSearch"
		></ScrollPagination>
	</div>
</template>

<script>
	import ScrollPagination from "@/components/ScrollPagination";
	import Empty from "@/view/Layout/Empty";
	export default {
		name: 'MafiaTvListSeries'
		, components: {Empty, ScrollPagination}
		, props: ['user', 'view_type', 'is_home', 'keyword', 'is_search']
		, data: function(){
			return {
				items_series: []
				, item_search: {
					srchtext: this.keyword
					, page_number: 1
					, pagerecnum: 10
				}
				, max: false
				, is_add: true
			}
		}
		, computed: {
			list_series: function(){
				let t = []
				this.items_series.filter( (item, index) => {
					if(this.view_type == 'series'){

						item.cover_img = ''
						item.post_file_list.filter ( (file) => {
							if(file.post_appendix_file_div_code == 'CA01000001'){
								item.cover_img = file.post_appendix_file_org_url
							}
						})
						t.push(item)
					}else{

						if(index < 4){
							item.cover_img = ''
							item.post_file_list.filter ( (file) => {
								if(file.post_appendix_file_div_code == 'CA01000001'){
									item.cover_img = file.post_appendix_file_org_url
								}
							})
							t.push(item)
						}
					}
				})
				return t
			}
		}
		, methods: {

			getData: async function(){
				try{
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.get_open_tv_series
					if(this.is_home){
						url = this.$api_url.api_path.get_open_home_tv_series
					}
					if(this.user.member_number){
						url = this.$api_url.api_path.get_cartel_tv_series_list
						if(this.is_home){
							url = this.$api_url.api_path.get_home_series
						}
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
							, srchtext: this.item_search.srchtext
						}
						, type: true
					})

					if(result.success){
						if(result.data.post_list.length > 0){
							if(this.item_search.page_number == 1){
								this.items_series = result.data.post_list
							}else{
								this.items_series = this.items_series.concat(result.data.post_list)
							}
							this.max = false
						}else{
							if(this.item_search.page_number == 1) {
								this.items_series = []
							}
							this.max = true
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toEpisode: function(item){

				this.$bus.$emit('to', { name: 'MafiaTvEpisode', params: { idx: item.cartl_number ? item.cartl_number : this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number ? item.board_number : this.$route.params.b_id, p_id: item.bulletin_number}, query: { from: this.is_home ? this.is_home : ''}})
			}
			, getSearch: function(page){
				this.item_search.page_number = page
				this.getData()
			}
		}
		, created() {
			this.getData()
		}
		, watch: {
			is_search: {
				handler: function(call){
					console.log('series', call, this.keyword)
					if(call){
						this.item_search.srchtext = this.keyword
						this.item_search.page_number = 1
						this.getData()
						this.$emit('click')
					}
				}
			}
		}
	}
</script>