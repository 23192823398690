<template>

	<div ref="post_list_item_view">
		<CartelBbsList
			:user="user"
			:items_list="items_cartel_bbs"
			:max="max"
			:is_add="is_add"
			:item_search="item_search"
			:item_cartel="item_cartel"
			:from="from"
			:is_sample="true"

			@click="update"
		></CartelBbsList>

		<ScrollPagination
			v-if="view_type == 'all' && $refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getSearch"
		></ScrollPagination>
	</div>
</template>

<script>
	import CartelBbsList from "@/view/Cartel/CartelBbsList";
	import ScrollPagination from "@/components/ScrollPagination";
	export default {
		name: 'MafiaTvListAll'
		, components: {ScrollPagination, CartelBbsList}
		, props: ['user', 'is_home', 'view_type', 'item_cartel', 'keyword', 'is_search']
		, data: function(){
			return {
				items_cartel_bbs: []
				, item_search: {
					srchtext: this.keyword
					, page_number: 1
					, pagerecnum: 10
				}
				, max: false
				, is_add: true
				, from: {
					name: 'MafiaTvList'
					, params: {
						idx: this.$route.params.idx
						, code: this.$route.params.code
						, b_id: this.$route.params.b_id
					}
				}
			}
		}
		, computed: {

		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.get_open_tv_alone_list
					if(this.is_home){
						url = this.$api_url.api_path.get_open_home_tv_episode
					}
					if(this.user.member_number){

						url = this.$api_url.api_path.get_cartel_tv_alone_list
						if(this.is_home){
							url = this.$api_url.api_path.get_home_episode
						}
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
							, srchtext: this.item_search.srchtext
						}
						, type: true
					})

					if(result.success){
						if(result.data.post_list.length > 0){
							if(this.item_search.page_number == 1){
								this.items_cartel_bbs = result.data.post_list
							}else{
								this.items_cartel_bbs = this.items_cartel_bbs.concat(result.data.post_list)
							}
							this.max = false
						}else{
							if(this.item_search.page_number == 1) {
								this.items_cartel_bbs = []
							}
							this.max = true
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, update: function(item){

				console.log('update', item.index, item)
				this.$set(this.items_cartel_bbs, item.index, item)
			}
			, getSearch: function(page){
				this.item_search.page_number = page
				this.getData()
			}
		}
		, created() {
			this.getData()
		}
		, watch: {
			is_search: {
				handler: function(call){
					console.log('all', call, this.keyword)
					if(call){

						this.item_search.srchtext = this.keyword
						this.item_search.page_number = 1
						this.getData()
						this.$emit('click')
					}
				}
			}
		}
	}
</script>